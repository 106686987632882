// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.palette-container {
    display: flex;
    flex-direction: row;
    
    border: 1px solid;
    background-color: white;

    width: 100%;
    height: max-content;

    overflow-x: scroll;
}

.color-choice {
    width: 10vh;
    height: 4vh;
    border: .1vh solid;
    flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/css/ColorPalette.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;;IAEnB,iBAAiB;IACjB,uBAAuB;;IAEvB,WAAW;IACX,mBAAmB;;IAEnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".palette-container {\n    display: flex;\n    flex-direction: row;\n    \n    border: 1px solid;\n    background-color: white;\n\n    width: 100%;\n    height: max-content;\n\n    overflow-x: scroll;\n}\n\n.color-choice {\n    width: 10vh;\n    height: 4vh;\n    border: .1vh solid;\n    flex-shrink: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
