// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loader__QXXr7 {
    border: 2px solid black;
    background-color: white;

    height: 40px;
    aspect-ratio: 1.5;
    --c1:no-repeat linear-gradient(#bc0036 0 0);
    --c2:no-repeat linear-gradient(#93b2fc 0 0);
    --c3:no-repeat linear-gradient(#7eea57 0 0);
    --c4:no-repeat linear-gradient(#fdd635 0 0);
    background: var(--c1), var(--c2), var(--c3), var(--c4);
    background-size: 33.4% 50%;
    animation: Loader_l6__rUZ8x 2s infinite linear;
  }
  @keyframes Loader_l6__rUZ8x {
    0%    {background-position:0 0,50% 0,0 100%,50% 100%}
    12.5% {background-position:0 0,100% 0,0 100%,50% 100%}
    25%   {background-position:0 0,100% 0,0 100%,50% 0}
    37.5% {background-position:0 0,100% 0,50% 100%,50% 0}
    50%   {background-position:0 100%,100% 0,50% 100%,50% 0}
    62.5% {background-position:0 100%,100% 0,50% 100%,0 0}
    75%   {background-position:0 100%,100% 100%,50% 100%,0 0}
    87.5% {background-position:0 100%,100% 100%,50% 0,0 0}
    100%  {background-position:0 100%,50% 100%,50% 0,0 0}
  }`, "",{"version":3,"sources":["webpack://./src/css/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,uBAAuB;;IAEvB,YAAY;IACZ,iBAAiB;IACjB,2CAA2C;IAC3C,2CAA2C;IAC3C,2CAA2C;IAC3C,2CAA2C;IAC3C,sDAAsD;IACtD,0BAA0B;IAC1B,8CAAgC;EAClC;EACA;IACE,OAAO,6CAA6C;IACpD,OAAO,8CAA8C;IACrD,OAAO,2CAA2C;IAClD,OAAO,6CAA6C;IACpD,OAAO,gDAAgD;IACvD,OAAO,8CAA8C;IACrD,OAAO,iDAAiD;IACxD,OAAO,8CAA8C;IACrD,OAAO,6CAA6C;EACtD","sourcesContent":[".loader {\n    border: 2px solid black;\n    background-color: white;\n\n    height: 40px;\n    aspect-ratio: 1.5;\n    --c1:no-repeat linear-gradient(#bc0036 0 0);\n    --c2:no-repeat linear-gradient(#93b2fc 0 0);\n    --c3:no-repeat linear-gradient(#7eea57 0 0);\n    --c4:no-repeat linear-gradient(#fdd635 0 0);\n    background: var(--c1), var(--c2), var(--c3), var(--c4);\n    background-size: 33.4% 50%;\n    animation: l6 2s infinite linear;\n  }\n  @keyframes l6 {\n    0%    {background-position:0 0,50% 0,0 100%,50% 100%}\n    12.5% {background-position:0 0,100% 0,0 100%,50% 100%}\n    25%   {background-position:0 0,100% 0,0 100%,50% 0}\n    37.5% {background-position:0 0,100% 0,50% 100%,50% 0}\n    50%   {background-position:0 100%,100% 0,50% 100%,50% 0}\n    62.5% {background-position:0 100%,100% 0,50% 100%,0 0}\n    75%   {background-position:0 100%,100% 100%,50% 100%,0 0}\n    87.5% {background-position:0 100%,100% 100%,50% 0,0 0}\n    100%  {background-position:0 100%,50% 100%,50% 0,0 0}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loader_loader__QXXr7`,
	"l6": `Loader_l6__rUZ8x`
};
export default ___CSS_LOADER_EXPORT___;
