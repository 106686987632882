// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#btn-closemodal {
    position: absolute;

    margin-right: 1vh;
    margin-top: 1vh;

    top: 0;
    right: 0;
}

.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0,0,0,0.5);

    cursor: default;

    z-index: 1;
}

.modal-elements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    width: fit-content;
    height: fit-content;

    background-color: white;
    border-radius: 1vw;
    border: 3px solid black;
}

.modal-elements .form-group {
    margin-bottom: 1vh;
}

.modal-elements h1,
.modal-elements form {
    margin-left: 2vw;
    margin-right: 2vw;
}

.btn-submit_container {
    display: flex;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/css/Modal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,iBAAiB;IACjB,eAAe;;IAEf,MAAM;IACN,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;;IAElB,YAAY;IACZ,aAAa;;IAEb,iCAAiC;;IAEjC,eAAe;;IAEf,UAAU;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;;IAEnB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;;IAEnB,uBAAuB;IACvB,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;;IAEI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":["#btn-closemodal {\n    position: absolute;\n\n    margin-right: 1vh;\n    margin-top: 1vh;\n\n    top: 0;\n    right: 0;\n}\n\n.modal-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n\n    width: 100vw;\n    height: 100vh;\n\n    background-color: rgba(0,0,0,0.5);\n\n    cursor: default;\n\n    z-index: 1;\n}\n\n.modal-elements {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    position: relative;\n    width: fit-content;\n    height: fit-content;\n\n    background-color: white;\n    border-radius: 1vw;\n    border: 3px solid black;\n}\n\n.modal-elements .form-group {\n    margin-bottom: 1vh;\n}\n\n.modal-elements h1,\n.modal-elements form {\n    margin-left: 2vw;\n    margin-right: 2vw;\n}\n\n.btn-submit_container {\n    display: flex;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
