// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --cursor-size: 16px;
}

html, body, #root {
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;

    touch-action: none
}

.bot {
    bottom: 2vh;
    margin: 0;
    position: fixed;
}

.top {
    top: 2vh;
    margin: 0;
    position: fixed;
}

.left {
    left: 2vh;
    margin: 0;
    position: fixed;
}

.right {
    right: 2vh;
    margin: 0;
    position: fixed;
}
`, "",{"version":3,"sources":["webpack://./src/css/Global.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,SAAS;IACT,UAAU;;IAEV;AACJ;;AAEA;IACI,WAAW;IACX,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,QAAQ;IACR,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,SAAS;IACT,eAAe;AACnB","sourcesContent":[":root {\n    --cursor-size: 16px;\n}\n\nhtml, body, #root {\n    height: 100%;\n    overflow: hidden;\n    margin: 0;\n    padding: 0;\n\n    touch-action: none\n}\n\n.bot {\n    bottom: 2vh;\n    margin: 0;\n    position: fixed;\n}\n\n.top {\n    top: 2vh;\n    margin: 0;\n    position: fixed;\n}\n\n.left {\n    left: 2vh;\n    margin: 0;\n    position: fixed;\n}\n\n.right {\n    right: 2vh;\n    margin: 0;\n    position: fixed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
