// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/customcursor_mask.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomCursor_custom-cursor_container__4w4mI {
    position: absolute;
}

.CustomCursor_custom-cursor_color__d-N2d {
    position: absolute;
    pointer-events: none;
    width: var(--cursor-size);
    height: var(--cursor-size);
    background-color: red;
    -webkit-mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    mask-repeat: no-repeat;
    mask-size: contain;
}

.CustomCursor_custom-cursor_outline__aaUeg {
    position: absolute;
    pointer-events: none;
    width: var(--cursor-size);
    height: var(--cursor-size);
}`, "",{"version":3,"sources":["webpack://./src/css/CustomCursor.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,yBAAyB;IACzB,0BAA0B;IAC1B,qBAAqB;IACrB,2DAA0D;IAC1D,8BAA8B;IAC9B,0BAA0B;IAC1B,mDAAkD;IAClD,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,yBAAyB;IACzB,0BAA0B;AAC9B","sourcesContent":[".custom-cursor_container {\n    position: absolute;\n}\n\n.custom-cursor_color {\n    position: absolute;\n    pointer-events: none;\n    width: var(--cursor-size);\n    height: var(--cursor-size);\n    background-color: red;\n    -webkit-mask-image: url('../images/customcursor_mask.png');\n    -webkit-mask-repeat: no-repeat;\n    -webkit-mask-size: contain;\n    mask-image: url('../images/customcursor_mask.png');\n    mask-repeat: no-repeat;\n    mask-size: contain;\n}\n\n.custom-cursor_outline {\n    position: absolute;\n    pointer-events: none;\n    width: var(--cursor-size);\n    height: var(--cursor-size);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-cursor_container": `CustomCursor_custom-cursor_container__4w4mI`,
	"custom-cursor_color": `CustomCursor_custom-cursor_color__d-N2d`,
	"custom-cursor_outline": `CustomCursor_custom-cursor_outline__aaUeg`
};
export default ___CSS_LOADER_EXPORT___;
