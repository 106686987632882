// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cellinfos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: fit-content;
    height: fit-content;
    background-color: white;

    border-radius: 1vw;
    border: 2px solid black;
}

.cellinfos-container * {
    margin-left: 1vw;
    margin-right: 1vw;
    margin-bottom: 0.5vh;
}`, "",{"version":3,"sources":["webpack://./src/css/CellInfos.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;;IAEvB,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;;IAEvB,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":[".cellinfos-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    width: fit-content;\n    height: fit-content;\n    background-color: white;\n\n    border-radius: 1vw;\n    border: 2px solid black;\n}\n\n.cellinfos-container * {\n    margin-left: 1vw;\n    margin-right: 1vw;\n    margin-bottom: 0.5vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
