// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.size-panel {

}

.palette-panel {

}

.palette-editor {
    display: flex;
    flex-direction: row;
}

.color-editor {
    display: flex;
    flex-direction: column;
}

.color-preview {
    flex: 1 0 20%;
    width: 8vh;
    height: 8vh;
    border: .2vh solid;
}

.palette-container_admin {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;

    border: 1px solid;
    background-color: white;

    width: 30vw;
    height: 20vh;

    overflow-y: scroll;
}

.color-choice_admin {
    width: 10vh;
    height: 4vh;
    border: .1vh solid;
    flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/css/AdminPanel.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;;AAEA;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,UAAU;IACV,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,yBAAyB;;IAEzB,iBAAiB;IACjB,uBAAuB;;IAEvB,WAAW;IACX,YAAY;;IAEZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".size-panel {\n\n}\n\n.palette-panel {\n\n}\n\n.palette-editor {\n    display: flex;\n    flex-direction: row;\n}\n\n.color-editor {\n    display: flex;\n    flex-direction: column;\n}\n\n.color-preview {\n    flex: 1 0 20%;\n    width: 8vh;\n    height: 8vh;\n    border: .2vh solid;\n}\n\n.palette-container_admin {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    align-content: flex-start;\n\n    border: 1px solid;\n    background-color: white;\n\n    width: 30vw;\n    height: 20vh;\n\n    overflow-y: scroll;\n}\n\n.color-choice_admin {\n    width: 10vh;\n    height: 4vh;\n    border: .1vh solid;\n    flex-shrink: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
