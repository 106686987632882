// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#drawing-board,
#canvas-cursor {    
    image-rendering: pixelated;
}

#cell {
    background-color: white;
    width: 2%;
    height: 2%;
}

#drawing-board {
    background-color: #FFFFFF;
    display: flex;
    flex-wrap: wrap;
}



#canvas-cursor {
    position: absolute;
    pointer-events: none;

    /* disable image selection so user can click multiple times without highlighting the image */
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/css/DrawingBoard.css"],"names":[],"mappings":"AAAA;;IAEI,0BAA0B;AAC9B;;AAEA;IACI,uBAAuB;IACvB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,eAAe;AACnB;;;;AAIA;IACI,kBAAkB;IAClB,oBAAoB;;IAEpB,4FAA4F;IAE5F,oBAAoB;IAEpB,yBAAyB;IACzB,iBAAiB;AACrB","sourcesContent":["#drawing-board,\n#canvas-cursor {    \n    image-rendering: pixelated;\n}\n\n#cell {\n    background-color: white;\n    width: 2%;\n    height: 2%;\n}\n\n#drawing-board {\n    background-color: #FFFFFF;\n    display: flex;\n    flex-wrap: wrap;\n}\n\n\n\n#canvas-cursor {\n    position: absolute;\n    pointer-events: none;\n\n    /* disable image selection so user can click multiple times without highlighting the image */\n    -khtml-user-select: none;\n    -o-user-select: none;\n    -moz-user-select: none;\n    -webkit-user-select: none;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
