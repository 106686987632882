// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
    display: flex;
    flex-direction: row;

    z-index: 1;
}

.header-container button {
    margin: 0.25vw;
}`, "",{"version":3,"sources":["webpack://./src/css/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;;IAEnB,UAAU;AACd;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".header-container {\n    display: flex;\n    flex-direction: row;\n\n    z-index: 1;\n}\n\n.header-container button {\n    margin: 0.25vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
