// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-board {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F3F3F3; 
    cursor: none;
}

#move-controller {
    position: relative;
    margin-inline: auto; 
    width: fit-content;
    border: 10px solid;
}
#zoom-controller {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    zoom: 1;
    z-index: 0;
}

.bottom-container {
    display: flex;
    flex-direction: column;
    gap: 0.5vh;

    align-items: center;
    width: 87%;
}

@media (max-width: 768px) {
    .main-board {
        cursor: pointer;
    }
  }`, "",{"version":3,"sources":["webpack://./src/css/MainBoard.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,OAAO;IACP,UAAU;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;;IAEV,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI;QACI,eAAe;IACnB;EACF","sourcesContent":[".main-board {\n    width: 100%;\n    height: 100%;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #F3F3F3; \n    cursor: none;\n}\n\n#move-controller {\n    position: relative;\n    margin-inline: auto; \n    width: fit-content;\n    border: 10px solid;\n}\n#zoom-controller {\n    position: absolute;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    zoom: 1;\n    z-index: 0;\n}\n\n.bottom-container {\n    display: flex;\n    flex-direction: column;\n    gap: 0.5vh;\n\n    align-items: center;\n    width: 87%;\n}\n\n@media (max-width: 768px) {\n    .main-board {\n        cursor: pointer;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
